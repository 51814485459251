import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import BloodRequests from "./pages/BloodRequests";
import AddEditNews from "./pages/Dashboard/AddEditNews";
import IndividualNews from "./pages/Dashboard/IndividualNews";
import Logout from "./pages/Dashboard/Logout";
import MainPage from "./pages/Dashboard/MainPage";
import News from "./pages/Dashboard/News";
import Profile from "./pages/Dashboard/Profile";
import RequestsPage from "./pages/Dashboard/RequestsPage";
import {
  EnterCode,
  ForgotPassword,
  PasswordChanged,
  ResetPassword,
} from "./pages/ForgotPassword";
import Login from "./pages/Login";
import UserRequest from "./pages/UserRequest";
import SendBloodRequest from "./pages/BloodRequests/SendBloodRequest";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<RequireAuth />} />
        <Route path="prijava" element={<Outlet />}>
          <Route path="" element={<Login />} />
          <Route path="zaboravljena-lozinka" element={<Outlet />}>
            <Route path="" element={<ForgotPassword />} />
            <Route path="potvrda" element={<EnterCode />} />
            <Route path="reset" element={<ResetPassword />} />
            <Route path="kraj" element={<PasswordChanged />} />
          </Route>
        </Route>

        <Route element={<PersistLogin />}>
          <Route path="kontrolna-ploca" element={<RequireAuth />}>
            <Route path="" element={<MainPage />} />
            <Route path="zahtjev-prijava" element={<Outlet />}>
              <Route path=":id" element={<UserRequest />} />
            </Route>
            <Route path="zahtjevi" element={<Outlet />}>
              <Route path=":status" element={<Outlet />}>
                <Route path="" element={<RequestsPage />} />
                <Route path=":id" element={<BloodRequests />} />
              </Route>
            </Route>
            <Route path="profil" element={<Profile />} />
            <Route path="novosti" element={<Outlet />}>
              <Route path="" element={<News />} />
              <Route path=":id" element={<Outlet />}>
                <Route path="" element={<IndividualNews />} />
                <Route path="uredi" element={<AddEditNews />} />
              </Route>
              <Route path="dodaj" element={<AddEditNews />} />
            </Route>
            <Route path="slanje-zahtjeva" element={<SendBloodRequest />} />
            <Route path="odjava" element={<Logout />} />
          </Route>
        </Route>

        <Route path="*" element={<RequireAuth />} />
      </Routes>
    </Router>
  );
}

export default App;
