import { FC, Dispatch, SetStateAction } from "react";
import { NewsType } from "../NewsItems/NewsItems";
import RequestButtons from "../RequestButtons";

type Props = {
  id?: number;
  title?: string;
  description?: string;
  onFailHandler: Function;
  onSuccessHandler: Function;
  successTitle?: string;
  failTitle?: string;
  setNews: Dispatch<SetStateAction<NewsType | null>>;
};

const IndividualNewsItem: FC<Props> = ({
  id,
  title,
  description,
  onFailHandler,
  setNews,
  onSuccessHandler,
  successTitle = "Spremi promejene",
  failTitle = "Odbaci",
}) => {
  return (
    <>
      <div className="flex flex-col mb-7">
        <label htmlFor="title">Naziv novosti</label>
        {title ? (
          <input
            type="text"
            id="title"
            placeholder="Unesite naziv novosti..."
            className="mt-2 w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-neutral-100 text-xl font-bold text-dark appearance-none"
            value={title}
            onChange={(e) =>
              setNews((prevState: any) => {
                return { ...prevState, title: e.target.value };
              })
            }
          />
        ) : (
          <input
            type="text"
            id="title"
            placeholder="Unesite naziv novosti..."
            className="mt-2 w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-neutral-100 text-xl font-bold text-dark appearance-none"
            onChange={(e) =>
              setNews((prevState: any) => {
                return { ...prevState, title: e.target.value };
              })
            }
          />
        )}
      </div>

      <div className="flex flex-col mb-7">
        <label htmlFor="desc">Opis</label>

        {description ? (
          <textarea
            name="desc"
            value={description}
            id="desc"
            className="mt-2 w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-neutral-100 text-xl text-dark appearance-none min-h-[300px] max-h-[600px]"
            onChange={(e) =>
              setNews((prevState: any) => {
                return { ...prevState, excerpt: e.target.value };
              })
            }
          >
            {description}
          </textarea>
        ) : (
          <textarea
            name="desc"
            value={description}
            id="desc"
            placeholder="Unesite opis novosti"
            className="mt-2 w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-neutral-100 text-xl text-dark appearance-none min-h-[300px] max-h-[600px]"
            onChange={(e) =>
              setNews((prevState: any) => {
                return { ...prevState, excerpt: e.target.value };
              })
            }
          ></textarea>
        )}
      </div>

      <div>
        <RequestButtons
          onFailHandler={onFailHandler}
          onSuccessHandler={onSuccessHandler}
          successTitle={successTitle}
          failTitle={failTitle}
          className="md:bg-transparent md:border-none"
        />
      </div>
    </>
  );
};

export default IndividualNewsItem;
