import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import IndividualNewsItem from "../../components/IndividualNewsItem";
import { NewsType } from "../../components/NewsItems/NewsItems";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const transformDate = (date: string) => {
  let stringDate = new Date(date);

  return `${stringDate.getDate()}/${
    stringDate.getMonth() + 1
  }/${stringDate.getFullYear()}`;
};

const AddEditNews = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [news, setNews] = useState<NewsType | null>(null);

  useEffect(() => {
    let loadNews = async () => {
      if (id) {
        const { data } = await axiosPrivate.get(`/news/${id}`);

        setNews({
          id: data.Id,
          title: data.HeaderText,
          excerpt: data.Text,
          date: transformDate(data.CreatedTime),
        });
      }
    };

    loadNews();
  }, []);

  useEffect(() => {
    console.log(news);
  }, [news]);

  const createNews = async () => {
    if (news && news.title && news.excerpt) {
      if (news.title.length > 3 && news.excerpt.length > 20) {
        await axiosPrivate.post("/news", {
          headerText: news?.title,
          text: news?.excerpt,
        });
        navigate(-1);
      } else {
        throw new Error("Bad request");
      }
    }
  };

  const updateNews = async () => {
    if (id) {
      if (news && news.title && news.excerpt) {
        if (news.title.length > 3 && news.excerpt.length > 20) {
          await axiosPrivate.put(`/news/${id}`, {
            headerText: news?.title,
            text: news?.excerpt,
          });
          navigate(-1);
        } else {
          throw new Error("Bad request");
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <>
        {id ? (
          <IndividualNewsItem
            onFailHandler={() => navigate(-1)}
            onSuccessHandler={updateNews}
            setNews={setNews}
            title={news?.title}
            description={news?.excerpt}
            id={news?.id}
          />
        ) : (
          <IndividualNewsItem
            onFailHandler={() => navigate(-1)}
            onSuccessHandler={createNews}
            setNews={setNews}
            successTitle="Dodaj novost"
          />
        )}
      </>

      <div></div>
    </DashboardLayout>
  );
};

export default AddEditNews;
