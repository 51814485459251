import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import RequestButtons from "../../components/RequestButtons";
import UserInfoPoints from "../../components/UserInfoPoints";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

type UserInfoType = {
  fullName: string;
  jmbg: string;
  number: string;
  dob: string;
  address: string;
  place: string;
  dates: string[];
  group: string;
  imageUrl: string;
};

const transformDate = (date: string) => {
  let stringDate = new Date(date);

  return `${stringDate.getDate()}/${
    stringDate.getMonth() + 1
  }/${stringDate.getFullYear()}`;
};

const UserRequest = () => {
  const [userInfo, setUserInfo] = useState<UserInfoType>({
    fullName: "",
    jmbg: "",
    number: "",
    address: "",
    dates: [],
    dob: "",
    group: "",
    imageUrl: "",
    place: "",
  });
  const [statusOfRequest, setStatusOfRequest] = useState<
    boolean | null | undefined
  >(undefined);

  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let loadUser = async () => {
      const { data } = await axiosPrivate.get(`/user/${id}`);
      let confirmationImage = "";

      const fileData = await axiosPrivate.get(`/file`, {
        params: { UserId: id },
      });

      if (fileData.data.length) {
        confirmationImage = fileData.data[0].Url;
      }

      setUserInfo({
        fullName: `${data.FirstName} ${data.LastName}`,
        jmbg: data.JMBG,
        number: data.PhoneNumber,
        dob: transformDate(data.BirthDate),
        address: data.Address,
        place: data.LocalCommunity,
        dates: data.Donations.map((d: { Date: string }) =>
          transformDate(d.Date)
        ),
        group: data.bloodType?.type,
        imageUrl: confirmationImage,
      });

      setStatusOfRequest(data.VerifiedByOwner);
    };

    loadUser();
  }, []);

  const renderStatus = () => {
    if (statusOfRequest === true) {
      return (
        <p className="w-full text-green-500 font-bold text-center">
          Zahtjev odobren
        </p>
      );
    } else if (statusOfRequest === false) {
      return (
        <p className="w-full text-red-500 font-bold text-center">
          Zahtjev odbijen
        </p>
      );
    }

    return <></>;
  };

  const renderButtons = () => {
    if (statusOfRequest === null) {
      return (
        <RequestButtons
          onFailHandler={refuseRequest}
          onSuccessHandler={approveRequest}
        />
      );
    }
    return <></>;
  };

  const approveRequest = async () => {
    try {
      const formData = new FormData();
      formData.append("status", "true");
      await axiosPrivate.put(`/user/verifyaccount?id=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      navigate(0);
    } catch (e) {
      console.log(e);
    }
  };

  const refuseRequest = async () => {
    try {
      const formData = new FormData();
      formData.append("status", "false");
      await axiosPrivate.put(`/user/verifyaccount?id=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      navigate(0);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DashboardLayout displayBackBtn={true}>
      <div className="md:bg-white shadow-none md:shadow-md rounded-md p-0 md:p-8">
        <p className="text-gray-600 text-center md:text-left ">
          Pregled zahtjeva
        </p>

        <div className="flex my-10 flex-col md:flex-row mb-40">
          <UserInfoPoints data={userInfo} />
        </div>
        {renderStatus()}
      </div>
      {renderButtons()}
    </DashboardLayout>
  );
};

export default UserRequest;
