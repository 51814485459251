import {
  BanIcon,
  CheckCircleIcon,
  ClockIcon,
  LogoutIcon,
  NewspaperIcon,
  HomeIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/outline";

import { NavLink } from "react-router-dom";
import { FC, Dispatch, SetStateAction } from "react";

type Props = {
  setToggleNavigation: Dispatch<SetStateAction<boolean>>;
};

const Navigation: FC<Props> = ({ setToggleNavigation }) => {
  const navLinkClickHandler = () => {
    setToggleNavigation(false);
  };

  return (
    <ul className="mt-2 md:mt-10 mx-auto md:block">
      <li className="flex items-center text-gray-900  inline-block p-1 mb-3">Upravljanje korisnicima</li>
      <li>
        <NavLink
          end
          to="/kontrolna-ploca"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <HomeIcon className="w-7 h-7 inline mr-2 stroke-1" /> Zahtjevi
        </NavLink>
      </li>
      <hr /> <br />
      <li className="flex items-center text-gray-900  inline-block p-2 mb-3">Upravljanje zahtjevima</li>
      <li>
        <NavLink
          to="/kontrolna-ploca/zahtjevi/na-cekanju"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <ClockIcon className="w-7 h-7 inline mr-2 stroke-1" /> Na čekanju
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/kontrolna-ploca/zahtjevi/odbijeni"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <BanIcon className="w-7 h-7 inline mr-2 stroke-1" /> Odbijeni
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/kontrolna-ploca/zahtjevi/prihvaceni"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <CheckCircleIcon className="w-7 h-7 inline mr-2 stroke-1" />{" "}
          Prihvaćeni
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/kontrolna-ploca/slanje-zahtjeva"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <PaperAirplaneIcon
            className="w-7 h-7 inline mr-2 stroke-1"
            style={{ rotate: "90deg" }}
          />{" "}
          Slanje zahtjeva
        </NavLink>
      </li>
      <hr /><br />
      <li className="flex items-center text-gray-900  inline-block p-2 mb-3">Upravljanje novostima</li>

      <li>
        <NavLink
          to="/kontrolna-ploca/novosti"
          className="flex items-center text-gray-600 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3"
          onClick={navLinkClickHandler}
        >
          <NewspaperIcon className="w-7 h-7 inline mr-2 stroke-1" /> Novosti
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/kontrolna-ploca/odjava"
          className="flex items-center text-gray-800 hover:text-red-500 transition duration-200 ease-in-out inline-block p-2 mb-3 md:mt-16"
          onClick={navLinkClickHandler}
        >
          <LogoutIcon className="w-7 h-7 font-regular inline mr-2 stroke-1" />{" "}
          Odjava
        </NavLink>
      </li>
    </ul>
  );
};

export default Navigation;
