import {
  HeartIcon,
  LocationMarkerIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/outline";
import UserInfoPoint from "./UserInfoPoint";
import { FC } from "react";

type UserPoints = {
  fullName: string;
  jmbg: string;
  number: string;
  dob: string;
  address: string;
  place: string;
  dates: string[];
  group: string;
  imageUrl: string;
};

type Props = {
  data: UserPoints;
};

const stringToArray = (array: string[]): string => {
  let stringConcat = "";

  array.map((element) => (stringConcat += element + ", "));

  return stringConcat;
};

const UserInfoPoints: FC<Props> = ({ data }) => {
  return (
    <>
      <div className="basis-full md:basis-1/2">
        <UserInfoPoint
          icon={<UserIcon className="w-10 h-10 stroke-2 stroke-red-500" />}
          points={[
            { title: data.fullName, description: "Ime i prezime" },
            { title: data.jmbg, description: "JMBG" },
            { title: data.number, description: "Broj telefona" },
            { title: data.dob, description: "Datum rođenja" },
          ]}
        />

        <UserInfoPoint
          icon={
            <LocationMarkerIcon className="w-10 h-10 stroke-2 stroke-red-500" />
          }
          points={[
            {
              title: data.address,
              description: "Adresa stanovanja",
            },
            { title: data.place, description: "Mjesna zajednica" },
          ]}
        />

        <UserInfoPoint
          icon={<StarIcon className="w-10 h-10 stroke-2 stroke-red-500" />}
          points={[
            {
              title: stringToArray(data.dates),
              description: "Datumi redovnih darivanja krvi",
            },
          ]}
        />
      </div>
      <div className="basis-full md:basis-1/2">
        <UserInfoPoint
          icon={<HeartIcon className="w-10 h-10 stroke-2 stroke-red-500" />}
          points={[
            {
              title: data.group,
              description: "Krvna grupa",
              imageUrl: data.imageUrl,
            },
          ]}
        />
      </div>
    </>
  );
};

export default UserInfoPoints;
