import { PencilIcon } from "@heroicons/react/outline";
import { ChangeButton } from "../../components/Button/Button";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

const Profile = () => {
  return (
    <DashboardLayout>
      <div className="relative w-48">
        <img
          src="https://via.placeholder.com/200"
          className="rounded-full w-full"
          alt=""
        />
        <div className="absolute bg-gray-800 rounded-lg p-3 top-0 right-0">
          <PencilIcon className="w-6 h-6 text-white" />
        </div>
      </div>

      <div>
        <div className="mb-10">
          <h2 className="text-2xl font-bold mt-10 mb-10">Promijeni email</h2>
          <div className="flex flex-col mb-4">
            <label htmlFor="email">Trenutni email</label>
            <input
              id="email"
              type="text"
              value="johndoe@email.com"
              className="bg-neutral-200 rounded-lg p-5 border-2 border-neutral-300 cursor-not-allowed my-3 md:w-96"
              disabled
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="email">Novi email</label>
            <input
              id="email"
              type="text"
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
            />
          </div>

          <ChangeButton
            clickHandler={console.log}
            title="Promijeni email"
            className="w-full md:w-96"
          />
        </div>
      </div>

      <div>
        <div className="mb-10">
          <h2 className="text-2xl font-bold mt-10 mb-10">Promijeni lozinku</h2>
          <div className="flex flex-col mb-4">
            <label htmlFor="currentPw">Trenutna lozinka</label>
            <input
              id="currentPw"
              type="text"
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="currentPw">Nova lozinka</label>
            <input
              id="newPw"
              type="text"
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="confirmPw">Potvrdi lozinku</label>
            <input
              id="confirmPw"
              type="text"
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
            />
          </div>

          <ChangeButton
            clickHandler={console.log}
            title="Promijeni lozinku"
            className="w-full md:w-96"
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;
