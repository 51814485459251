import { FC } from "react";
import MainContainer from "../MainContainer/MainContainer";
import SidebarMenu from "../SidebarMenu";

type Props = {
  children: JSX.Element | JSX.Element[];
  displayBackBtn?: boolean;
};

const DashboardLayout: FC<Props> = ({ children, displayBackBtn }) => {
  return (
    <div className="flex flex-col md:flex-row h-full">
      <SidebarMenu />

      <MainContainer displayBack={displayBackBtn}>{children}</MainContainer>
    </div>
  );
};

export default DashboardLayout;
