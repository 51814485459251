import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TableEntriesProps } from "../../pages/Dashboard/MainPage";
import { PrimaryButton } from "../Button";

type Props = {
  tableEntries: TableEntriesProps[];
  tableHeaders: string[];
};

const Table: FC<Props> = ({ tableEntries, tableHeaders }) => {
  const navigate = useNavigate();

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-10 md:mt-20">
      <table className="w-full text-md text-left text-gray-900 font-semibold">
        <thead className="text-lg text-red-700 bg-white border-b border-gray-300">
          <tr>
            {tableHeaders.map((header, i) => {
              return (
                <th scope="col" key={i} className="px-6 py-3 text-center">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableEntries.map((entry, i) => {
            return (
              <tr className="bg-white border-b" key={i}>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
                >
                  {entry.imePrezime}
                </th>
                <td className="px-6 py-4 text-center">{entry.krvnaGrupa}</td>
                <td className="px-6 py-4 text-center">
                  {entry.statusZahtjeva}
                </td>
                {entry.brojDoza && (
                  <td className="px-6 py-4 text-center">{entry.brojDoza}</td>
                )}
                <td className="px-6 py-4 text-center">
                  <PrimaryButton
                    spacingClasses="mb-0 mt-0"
                    title="Pregledaj"
                    clickHandler={() => {
                      navigate(entry.link);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
