import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../General/Logo";
import ProfileInfo from "../General/ProfileInfo";
import Navigation from "./Navigation";

const SidebarMenu = () => {
  const [toggleNavigation, setToggleNavigation] = useState(false);

  return (
    <div className="w-full md:w-80 mx-auto p-5 md:p-10 shadow-md md:shadow-none z-10 md:border-r md:border-b-0 border-gray-200 md:h-full md:overflow-y-auto bg-white">
      <div className="flex justify-between items-center">
        <Link to="/kontrolna-ploca" className="md:mx-auto">
          <Logo className="w-16 h-16 md:w-36 md:h-36 md:mx-auto" />
        </Link>

        {!toggleNavigation ? (
          <MenuIcon
            className="w-9 h-9 md:hidden"
            onClick={() => setToggleNavigation(!toggleNavigation)}
          />
        ) : (
          <XIcon
            className="w-9 h-9 md:hidden"
            onClick={() => setToggleNavigation(!toggleNavigation)}
          />
        )}
      </div>

      <div className={`${!toggleNavigation ? "hidden" : ""} md:block  `}>
        <Link to="/kontrolna-ploca/profil">
          <ProfileInfo
            alt="Ime profilna slika"
            imageSrc="https://via.placeholder.com/56"
            className="md:hidden mt-5 hover:text-red-500"
            fullName="Ime i prezime"
          />
        </Link>

        <Navigation setToggleNavigation={setToggleNavigation} />
      </div>
    </div>
  );
};

export default SidebarMenu;
