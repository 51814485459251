import { ChangeEvent, FormEvent, useState, useRef, useEffect } from "react";
import { PrimaryButton, SimpleLinkButton } from "../../components/Button";
import Logo from "../../components/General/Logo";
import BasicInput from "../../components/Input/Input";
import { Navigate } from "react-router-dom";
import axios from "../../api/axios";
import { ShieldExclamationIcon } from "@heroicons/react/outline";
import useAuth from "../../hooks/useAuth";
import jwt from "jwt-decode";
const LOGIN_URL = "/authentication/login";

const Login = () => {
  const { setAuth } = useAuth();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const errorRef = useRef<HTMLParagraphElement | null>(null);
  const [password, setPassword] = useState("");
  const [navigate, setNavigate] = useState(false);

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(LOGIN_URL, {
        userName: email,
        password,
      });

      const decodedToken: any = jwt(data.Token);

      const userRole =
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

      if (userRole !== "Admin") {
        throw new Error("Not authorized");
      }

      localStorage.clear();
      localStorage.setItem(
        "tokens",
        JSON.stringify({
          at: data?.Token,
          rt: data?.RefreshToken,
        })
      );

      setAuth({
        accessToken: data?.Token,
        refreshToken: data?.RefreshToken,
        role: userRole,
      });

      setEmail("");
      setPassword("");
      setNavigate(true);
    } catch (e) {
      setError("Dogodila se greška prilikom prijave. Molimo pokušajte ponovo");
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setError("");
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  if (navigate) {
    return <Navigate to="/kontrolna-ploca" />;
  }

  return (
    <div
      className="bg-center bg-no-repeat bg-cover w-full h-full"
      style={{
        backgroundImage: "url(/images/background.jpg)",
      }}
    >
      <div className="w-full mx-auto py-1 md:w-2/3 lg:w-1/2 xl:w-1/3 relative">
        {error && (
          <p
            ref={errorRef}
            className="bg-red-800 w-full absolute rounded-lg text-white p-3 px-10 top-20 mx-auto text-center flex z-10 justify-center"
          >
            <ShieldExclamationIcon className="w-6 h-6 text-white mr-6" />
            {error}
          </p>
        )}
        <div className="w-28 h-28 md:w-36 md:h-36 bg-white flex justify-center align-items-center rounded-full mx-auto relative top-20">
          <Logo className="w-20 h-auto" />
        </div>

        <form
          className="bg-white p-5 md:p-10 pt-24 md:pt-32 rounded-lg"
          onSubmit={(e) => submitHandler(e)}
        >
          <BasicInput
            inputId="name"
            inputName="name"
            inputType="text"
            label="Korisničko ime"
            placeholder="Unesite Vaše korisničko ime"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            value={email}
          />

          <BasicInput
            inputId="password"
            inputName="password"
            inputType="password"
            label="Lozinka"
            placeholder="Unesite Vašu lozinku"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            value={password}
          />

          <PrimaryButton
            title="Prijavi se"
            clickHandler={() => {
              // navigate("/kontrolna-ploca");
            }}
          />

          <SimpleLinkButton
            path="/prijava/zaboravljena-lozinka"
            title="Zaboravili ste lozinku?"
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
