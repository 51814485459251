import { useNavigate } from "react-router-dom";
import Logo from "../../components/General/Logo";
import { PrimaryButton } from "../../components/Button";
import Announcement from "../../components/General/Announcement";

export const PasswordChanged = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-full h-full flex-col md:flex-row">
      <div className="basis-full md:basis-2/3 p-10">
        <div className="w-11/12 md:w-3/5 mx-auto">
          <Logo className="w-20 h-20 mx-auto my-10 md:my-20" />

          <h1 className="text-3xl font-bold text-center text-red-700">
            Čestitke!
          </h1>
          <h2 className="text-md md:text-xl text-center mb-10">
            Uspješno ste promijenili Vašu lozinku!
          </h2>

          <Announcement className="w-3/4 md:w-1/2 mx-auto" />

          <PrimaryButton
            className="w-2/3 mx-auto"
            title="Idi na prijavu"
            clickHandler={() => {
              navigate("../../");
            }}
          />
        </div>
      </div>
    </div>
  );
};
