import { Dispatch, FC, SetStateAction } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { PagingOptionProps } from "../../pages/Dashboard/MainPage";

type Props = {
  pagingOptions: PagingOptionProps;
  setPagingOptions: Dispatch<SetStateAction<PagingOptionProps>>;
};

const Paging: FC<Props> = ({ pagingOptions, setPagingOptions }) => {
  const clickPageHandler = (page: number) => {
    setPagingOptions({ ...pagingOptions, currentPage: page });
  };

  const renderAllPages = () => {
    let pagesArray: JSX.Element[] = [];
    for (let index = 1; index <= pagingOptions.totalPages; index++) {
      pagesArray.push(
        <li
          key={index}
          onClick={() => clickPageHandler(index)}
          className={`w-8 h-8 shadow-sm flex items-center justify-center font-sans font-bold rounded-md text-md cursor-pointer mr-2 ${
            pagingOptions.currentPage === index
              ? "bg-red-500 text-white"
              : "bg-none text-gray-500 hover:bg-red-500 hover:text-white transition duration-150 ease-in-out"
          }`}
        >
          {index}
        </li>
      );
    }

    return pagesArray;
  };

  return (
    <ul className="flex flex-wrap justify-center mt-20 pb-20">
      {pagingOptions.hasPrevious && (
        <li
          className="w-8 h-8 bg-neutral-200 text-gray-500 shadow-sm flex items-center justify-center font-sans font-bold rounded-md text-md cursor-pointer mr-2 hover:bg-red-500 hover:text-white transition duration-150 ease-in-out"
          onClick={() => clickPageHandler(pagingOptions.currentPage - 1)}
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </li>
      )}

      {renderAllPages().map((page) => page)}

      {pagingOptions.hasNext && (
        <li
          className="w-8 h-8 bg-neutral-200 text-gray-500 shadow-sm flex items-center justify-center font-sans font-bold rounded-md text-md cursor-pointer mr-2 hover:bg-red-500 hover:text-white transition duration-150 ease-in-out"
          onClick={() => clickPageHandler(pagingOptions.currentPage + 1)}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </li>
      )}
    </ul>
  );
};

export default Paging;
