import { SearchIcon } from "@heroicons/react/outline";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

type BloodTypes = {
  Id: number;
  Type: string;
  NumberOfDose: number;
};

type Props = {
  setBlood: Dispatch<SetStateAction<number | null>>;
  setSearchQuery: Dispatch<SetStateAction<string>>;
};
const BloodSearch: FC<Props> = ({ setBlood, setSearchQuery }) => {
  const [bloodTypes, setBloodTypes] = useState<BloodTypes[] | []>([]);

  const axiosPrivate = useAxiosPrivate();

  const onBloodChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setBlood(parseInt(e.target.value));
  };

  useEffect(() => {
    const loadBloodTypes = async () => {
      const { data } = await axiosPrivate.get("/bloodtype");

      setBloodTypes(data);
    };

    loadBloodTypes();
  }, []);

  return (
    <div className="flex">
      <div className="flex items-center border-2 border-neutral-200 bg-white py-4 px-5 rounded-lg basis-1/2 md:basis-3/4 mr-5">
        <SearchIcon className="w-5 h-5 mr-2 text-red-500" />
        <input
          type="text"
          name="search"
          placeholder="Ime ili prezime"
          className="w-full outline-none"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <div className="basis-1/2 md:basis-1/4">
        <select
          name="groups"
          id="groups"
          className="w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-white appearance-none"
          onChange={(e) => onBloodChange(e)}
        >
          <option value="all">Sve grupe</option>
          {bloodTypes.map((bloodType: BloodTypes, i) => {
            return (
              <option key={i} value={bloodType.Id}>
                {bloodType.Type}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default BloodSearch;
