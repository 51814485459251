import { FC } from "react";

type InputTypes = "button" | "checkbox" | "email" | "text" | "password";

type Props = {
  inputId: string;
  label: string;
  inputType: InputTypes;
  inputName: string;
  placeholder: string;
  onChange?: Function;
  value: string;
};

const BasicInput: FC<Props> = ({
  inputId,
  label,
  inputType,
  inputName,
  placeholder,
  value,
  onChange = () => {},
}) => {
  return (
    <div className="mb-5">
      <label
        className="block font-bold text-lg text-gray-800 mb-2"
        htmlFor={inputId}
      >
        {label}
      </label>
      <input
        className="bg-neutral-200 p-2 rounded-lg block w-full"
        type={inputType}
        name={inputName}
        id={inputId}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        value={value}
      />
    </div>
  );
};

export default BasicInput;
