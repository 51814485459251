import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ChangeButton, DangerButton } from "../Button/Button";
import { NewsType } from "./NewsItems";

type Props = {
  data: NewsType;
};

const NewsItem: FC<Props> = ({ data }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();

  const closeConfirm = () => {
    setDeleteConfirmation(false);
  };

  const deleteNews = async () => {
    const id = data.id;

    await axiosPrivate.delete(`/news/${id}`);

    navigate(0);
  };

  return (
    <>
      <div
        className="bg-white shadow-custom p-10 my-5 rounded-lg basis-full md:basis-[48%] relative"
        key={data.id}
      >
        {deleteConfirmation && (
          <div className="bg-white text-dark border-2 border-gray-200 shadow-custom rounded-lg p-5 absolute">
            <p className="text-lg">
              Da li ste sigurni da želite izbrisati novost?
            </p>
            <p className="text-lg font-bold">{data.title}</p>
            <div className="flex items-end mt-5 mr-0">
              <DangerButton
                title="Da"
                clickHandler={deleteNews}
                spacingClasses={""}
              />
              <ChangeButton title="Ne" clickHandler={closeConfirm} />
            </div>
          </div>
        )}

        <div className="flex items-center justify-between">
          <Link to={`/kontrolna-ploca/novosti/${data.id}`}>
            <h2 className="font-bold text-2xl mb-5 hover:text-gray-500 duration-150 transition-all ease-in-out">
              {data.title}
            </h2>
          </Link>
          <div className="flex mb-5">
            <Link
              to={`/kontrolna-ploca/novosti/${data.id}/uredi`}
              className="mr-3"
            >
              <PencilAltIcon className="w-6 h-6 text-green-600 hover:text-green-800 duration-150 transition-all ease-in-out" />
            </Link>

            <TrashIcon
              className="w-6 h-6 text-red-400 hover:text-red-900 duration-150 transition-all ease-in-out cursor-pointer"
              onClick={(e) => setDeleteConfirmation(true)}
            />
          </div>
        </div>
        <p>{data.excerpt}</p>
        <p className="mt-5 text-gray-500">{data.date}</p>
      </div>
    </>
  );
};

export default NewsItem;
