import { Link, useNavigate } from "react-router-dom";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import Logo from "../../components/General/Logo";
import BasicInput from "../../components/Input";
import { PrimaryButton } from "../../components/Button";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-full h-full flex-col md:flex-row">
      <div className="basis-full md:basis-2/3 p-10">
        <div className="w-11/12 md:w-3/5 mx-auto">
          <Link to="/prijava" className="font-semibold flex items-center">
            <ArrowNarrowLeftIcon className="w-7 h-7 inline mr-2" /> Nazad na
            prijavu
          </Link>

          <Logo className="w-40 h-40 mx-auto my-10 md:my-20" />

          <BasicInput
            inputId="name"
            inputName="name"
            inputType="text"
            label="Korisničko ime"
            placeholder="Unesite Vaše korisničko ime"
            value={"asd"}
          />

          <PrimaryButton
            title="Oporavi lozinku"
            clickHandler={() => {
              navigate("potvrda");
            }}
          />
        </div>
      </div>
      <div className="basis-full md:basis-1/3 p-10 bg-red-600 text-white font-bold flex flex-col justify-center ">
        <h1 className="text-xl md:text-2xl mb-5">Zaboravili ste lozinku</h1>
        <h2 className="text-md md:text-lg">
          Na Vaš mail stići će kod koji ćete morati unijeti na idućem koraku
          kako biste potvrdili svoj identitet.
        </h2>
      </div>
    </div>
  );
};
