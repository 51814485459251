import { FC } from "react";
import { DangerButton, SuccessButton } from "../Button/Button";

type Props = {
  onSuccessHandler: Function;
  onFailHandler: Function;
  successTitle?: string;
  failTitle?: string;
  className?: string;
};

const RequestButtons: FC<Props> = ({
  onSuccessHandler,
  onFailHandler,
  successTitle = "Prihvati zahtjev",
  failTitle = "Odbij zahtjev",
  className,
}) => {
  return (
    <div
      className={`fixed md:sticky z-10 bottom-0 left-0 right-0 border border-t-gray-300 bg-white flex justify-between md:justify-center items-center ${className}`}
    >
      <DangerButton
        clickHandler={onFailHandler}
        title={failTitle}
        className="w-full md:mr-4"
      />
      <SuccessButton
        clickHandler={onSuccessHandler}
        title={successTitle}
        className="w-full md:ml-4"
      />
    </div>
  );
};

export default RequestButtons;
