import { FC } from "react";
import { Link } from "react-router-dom";
import { GoBack } from "../Button/Button";
import ProfileInfo from "../General/ProfileInfo";
import jwtDecode from "jwt-decode";
import { axiosPrivate } from "../../api/axios";

type Props = {
  children: JSX.Element | JSX.Element[];
  displayBack?: boolean;
};

var imePrezime='';

var token=JSON.parse(
  localStorage.getItem('tokens') || JSON.stringify({ at: '', rt: '' })
)
// console.log(jwtDecode(token.at))

const loadUser = async () => {
  const { data } = await axiosPrivate.get("/user/"+(jwtDecode(token.at) as any).UserId);
  imePrezime=await data.FirstName+' '+data.LastName;
};

const MainContainer: FC<Props> = ({ children, displayBack }) => {
  if(imePrezime===''){
    loadUser();
  }
  return (
    <div className="w-full bg-gray-50 overflow-y-auto md:relative">
      {displayBack ? (
        <GoBack className="border-b border-gray-200 py-9 px-10 justify-center md:justify-start md:flex cursor-pointer" />
      ) : (
        <Link to="/kontrolna-ploca/profil">
          <ProfileInfo
            alt="Ime profilna slika"
            imageSrc="https://via.placeholder.com/56"
            className="border-b border-gray-200 py-7 px-10 hidden md:flex hover:text-red-500"
            fullName={imePrezime as string}
          />
        </Link>
      )}
      <div className="px-5 md:px-10 mt-10">{children}</div>
    </div>
  );
};

export default MainContainer;
