import { FC } from "react";

type Props = {
  imageSrc: string;
  alt: string;
  className?: string;
  fullName: string;
};

const ProfileInfo: FC<Props> = ({ imageSrc, alt, className, fullName }) => {
  return (
    <div
      className={`bg-white my-5 flex items-center text-gray-900 mt-0 ${className}`}
    >
      <img src={imageSrc} alt={alt} className="w-14 h-14 rounded-full mr-5" />
      <p className="font-semibold text-lg">{fullName}</p>
    </div>
  );
};

export default ProfileInfo;
