import { createContext, useState, FC, Dispatch, SetStateAction } from "react";

type AuthType = {
  accessToken: string;
  refreshToken: string;
  role: string;
};

type ContextType = {
  setAuth: Dispatch<SetStateAction<AuthType>>;
  auth: AuthType;
};

const initalValues: ContextType = {
  auth: {
    accessToken: "",
    refreshToken: "",
    role: "",
  },
  setAuth: () => {},
};

const AuthContext = createContext<ContextType>(initalValues);

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: "",
    refreshToken: "",
    role: "",
  });

  return (
    <AuthContext.Provider value={{ setAuth, auth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
