import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/outline";
import React from "react";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  title: string;
  path: string;
  clickHandler: Function;
  spacingClasses?: string;
};

export const PrimaryButton: FC<Omit<Props, "path">> = ({
  title,
  className,
  clickHandler,
  spacingClasses = "mb-5 mt-10",
}) => {
  return (
    <div className={spacingClasses}>
      <button
        className={`from-red-600 to-red-900 bg-gradient-to-r p-3 relative text-white cursor-pointer hover:bg-gradient-to-l rounded-lg block w-full ${className}`}
        onClick={() => clickHandler()}
      >
        {title}
      </button>
    </div>
  );
};

export const AddButton: FC<Omit<Props, "path">> = ({
  title,
  className,
  clickHandler,
  spacingClasses = "mb-5 mt-10",
}) => {
  return (
    <div>
      <button
        className={`bg-red-800 p-5 relative text-white cursor-pointer hover:bg-red-500 rounded-lg block w-full duration-150 transition ease-in-out flex items-center justify-center ${className}`}
        onClick={() => clickHandler()}
      >
        <PlusIcon className="w-4 h-4 mr-4" />
        {title}
      </button>
    </div>
  );
};

export const DangerButton: FC<Omit<Props, "path">> = ({
  title,
  className,
  clickHandler,
  spacingClasses = "mb-5 mt-10",
}) => {
  return (
    <div className={`${spacingClasses} mx-2 w-1/2 md:w-fit`}>
      <button
        className={`bg-red-600 p-3 relative text-white cursor-pointer hover:bg-red-900 rounded-lg block md:w-52 ${className}`}
        onClick={() => clickHandler()}
      >
        {title}
      </button>
    </div>
  );
};

export const SuccessButton: FC<Omit<Props, "path">> = ({
  title,
  className,
  clickHandler,
  spacingClasses = "mb-5 mt-10",
}) => {
  return (
    <div className={`${spacingClasses} mx-2 w-1/2 md:w-fit`}>
      <button
        className={`bg-green-700 p-3 relative text-white cursor-pointer hover:bg-green-900 rounded-lg block md:w-52 ${className}`}
        onClick={() => clickHandler()}
      >
        {title}
      </button>
    </div>
  );
};

export const ChangeButton: FC<Omit<Props, "path">> = ({
  title,
  className,
  clickHandler,
}) => {
  return (
    <div className={`ml-0 mx-2 w-full md:w-fit`}>
      <button
        className={`bg-green-700 p-3 relative text-white cursor-pointer hover:bg-green-900 rounded-lg block md:w-52 ${className}`}
        onClick={() => clickHandler()}
      >
        {title}
      </button>
    </div>
  );
};

export const SimpleLinkButton: FC<Omit<Props, "clickHandler">> = ({
  title,
  path,
  className,
}) => {
  return (
    <div className="mb-5">
      <Link
        to={path}
        className={`font-bold text-blue-600 text-center block ${className}`}
      >
        {title}
      </Link>
    </div>
  );
};

type GoBackProps = {
  className?: string;
};
export const GoBack: FC<GoBackProps> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`bg-white my-5 flex items-center text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out  mt-0 ${className}`}
      onClick={() => navigate(-1)}
    >
      <ChevronLeftIcon className="w-5 h-5 mr-5" />
      <p className="text-md">Nazad</p>
    </div>
  );
};
