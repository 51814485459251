import { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import RequestButtons from "../../components/RequestButtons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";

export type SendBloodRequestProps = {
  doseNumber: number;
  address: string;
  purposeForRequest: string;
  note: string;
  bloodTypeId: number;
  //status: true;
};

const SendBloodRequest = () => {
  const { register, handleSubmit, reset } = useForm<SendBloodRequestProps>();
  const [bloodTypes, setBloodTypes] = useState<any>([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    loadBloodTypes();
  }, []);

  let loadBloodTypes = async () => {
    const { data } = await axiosPrivate.get(`/bloodtype`);
    setBloodTypes(data);
  };

  const handleSubmitRequest = async (data: SendBloodRequestProps) => {
    try {
      const { data: response } = await axiosPrivate.post(`/bloodrequest`, data);
      console.log(response);
      reset();
    } catch (error) {
      console.log("🚀 ~error:", error);
    }
  };

  return (
    <DashboardLayout displayBackBtn={true}>
      <form onSubmit={(handleSubmit(handleSubmitRequest))}>
        <div className="md:bg-white shadow-none md:shadow-md rounded-md p-0 md:p-8">
          <div className="flex justify-between mb-5">
            <p className="text-gray-600 text-center md:text-left ">
              Slanje zahtjeva
            </p>
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="bloodTypeId">Potrebna krvna grupa</label>
            <select
              id="bloodTypeId"
              {...register("bloodTypeId", { required: true })}
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-300 my-3 md:w-96 appearance-none"
            >
              <option value={""}>Odaberi krvnu grupu</option>
              {bloodTypes.map((bloodType: any) => (
                <option value={bloodType.Id} key={bloodType.id}>
                  {bloodType.Type}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-4">
            <label htmlFor="doseNumber">Broj potrebnih doza</label>
            <input
              id="doseNumber"
              type="number"
              {...register("doseNumber", { required: true })}
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-300 my-3 md:w-96"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="address">Adresa gdje je doza potrebna</label>
            <input
              id="address"
              type="text"
              {...register("address", { required: true })}
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-300 my-3 md:w-96"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="purposeForRequest">
              Svrha za koju je potrebna doza
            </label>
            <input
              id="purposeForRequest"
              type="text"
              {...register("purposeForRequest", { required: true })}
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="note">Napomena</label>
            <textarea
              {...register("note", { required: true })}
              id="note"
              className="bg-neutral-100 rounded-lg p-5 border-2 border-neutral-200 my-3 md:w-96"
              rows={3}
            />
          </div>
        </div>
        <RequestButtons
          onFailHandler={() => reset()}
          successTitle="Pošalji zahtjev"
          failTitle="Poništi zahtjev"
          onSuccessHandler={()=>{}}
        />
      </form>
    </DashboardLayout>
  );
};

export default SendBloodRequest;
