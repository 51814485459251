import { FC } from "react";

type Points = {
  title: string;
  description: string;
  imageUrl?: string;
};

type Props = {
  icon: JSX.Element;
  points: Points[];
};

const BloogRequestInfoPoint: FC<Props> = ({ icon, points }) => {
  const centerClasses =
    points.length > 1 ? "md:items-start" : "md:items-center";
  const spacingClasses = points.length > 1 ? "mb-5" : "";
  return (
    <div
      className={`mb-10 md:mb-10 text-center md:text-left md:flex md:flex-row items-start ${centerClasses}`}
    >
      <div className="bg-gray-200 w-16 h-16 flex items-center justify-center rounded-md mx-auto md:mx-0">
        {icon}
      </div>
      <div className="flex flex-col justify-start">
        {points.map((point) => {
          return (
            <div className={`md:ml-5 ${spacingClasses}`}>
              <h2 className="font-bold text-lg">{point.title}</h2>
              <p className="text-gray-600 text-sm">{point.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BloogRequestInfoPoint;
