import { FC } from "react";

type Points = {
  title: string;
  description: string;
  imageUrl?: string;
};

type Props = {
  icon: JSX.Element;
  points: Points[];
};

const UserInfoPoint: FC<Props> = ({ icon, points }) => {
  return (
    <div className="mb-10 md:mb-14 text-center md:text-left">
      <div className="bg-gray-200 w-16 h-16 flex items-center justify-center rounded-md mb-3 mx-auto md:mx-0">
        {icon}
      </div>
      {points.map((point, i) => {
        return (
          <div className="mb-5" key={i}>
            <h2 className="font-bold text-lg">{point.title}</h2>
            <p className="text-gray-600 text-sm">{point.description}</p>
            {point.imageUrl && (
              <div className="mt-3 bg-black w-full h-full w-9/12 h-auto mx-auto md:m-0 md:w-96 md:h-96 flex items-center justify-center">
                <img src={point.imageUrl} alt="" className="w-full" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default UserInfoPoint;
