import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BloodSearch from "../../components/BloodSearch";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import Paging from "../../components/Paging/Paging";
import Table from "../../components/Table";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export type BloodEntriesProps = {
  id: number;
  imePrezime: string;
  krvnaGrupa: string;
  statusZahtjeva: string;
  brojDoza: number;
  link: string;
};

const beutifyStatus = (status: string) => {
  let statusNice = "";
  switch (status) {
    case "na-cekanju":
      statusNice = "Na čekanju";
      break;

    case "prihvaceni":
      statusNice = "Prihvaćen";
      break;

    case "odbijeni":
      statusNice = "Odbijen";
      break;

    default:
      statusNice = "N/A";
  }

  return statusNice;
};

const RequestsPage = () => {
  const [tableHeaders, setTableHeaders] = useState<string[]>([
    "Ime i prezime",
    "Potrebna krvna grupa",
    "Status zahtjeva",
    "Broj doza",
    "",
  ]);
  const [tableEntries, setTableEntries] = useState<BloodEntriesProps[]>([]);

  const [bloodType, setBloodType] = useState<number | null>(null);
  const [searchName, setSearchName] = useState<string>("");

  const navigate = useNavigate();
  const { status } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const statusType = status || "NA";

  useEffect(() => {
    const listOfStatus = ["na-cekanju", "prihvaceni", "odbijeni"];

    if (!listOfStatus.includes(statusType)) {
      navigate("/kontrolna-ploca");
    }

    let currentStatusTransofrmed: null | boolean = null;

    if (status === "prihvaceni") {
      currentStatusTransofrmed = true;
    } else if (status === "odbijeni") {
      currentStatusTransofrmed = false;
    }

    const controller = new AbortController();

    let loadBloodRequests = async () => {
      if (searchName.length !== 0) {
        return;
      }
      let request: any = "";

      if (!bloodType) {
        request = await axiosPrivate.get("/bloodrequest", {
          signal: controller.signal,
        });
      } else {
        request = await axiosPrivate.get("/bloodrequest", {
          params: { BloodTypeId: bloodType },
          signal: controller.signal,
        });
      }

      const { data } = request;
      let transformedArray: BloodEntriesProps[] = [];

      data.map((d: any) => {
        if (d.Status === currentStatusTransofrmed) {
          return transformedArray.push({
            id: d.Id,
            imePrezime: `${d.User.FirstName} ${d.User.LastName}`,
            krvnaGrupa: d.BloodType.Type,
            statusZahtjeva: beutifyStatus(statusType),
            brojDoza: d.DoseNumber,
            link: `/kontrolna-ploca/zahtjevi/${status}/${d.Id}`,
          });
        } else {
          return d;
        }
      });

      setTableEntries(transformedArray);
    };

    loadBloodRequests();

    return () => {
      controller.abort();
    };
  }, [status, statusType, navigate, bloodType, searchName]);

  useEffect(() => {
    const listOfStatus = ["na-cekanju", "prihvaceni", "odbijeni"];

    if (!listOfStatus.includes(statusType)) {
      navigate("/kontrolna-ploca");
    }

    let currentStatusTransofrmed: null | boolean = null;

    if (status === "prihvaceni") {
      currentStatusTransofrmed = true;
    } else if (status === "odbijeni") {
      currentStatusTransofrmed = false;
    }

    const controller = new AbortController();
    const delayedFn = setTimeout(async () => {
      if (searchName && searchName?.length > 2) {
        let request: any = "";
        if (!bloodType) {
          request = await axiosPrivate.get("/bloodrequest", {
            signal: controller.signal,
            params: { Name: searchName },
          });
        } else {
          request = await axiosPrivate.get("/bloodrequest", {
            params: { BloodTypeId: bloodType, Name: searchName },
            signal: controller.signal,
          });
        }

        const { data } = request;
        let transformedArray: BloodEntriesProps[] = [];

        data.map((d: any) => {
          if (d.Status === currentStatusTransofrmed) {
            return transformedArray.push({
              id: d.Id,
              imePrezime: `${d.User.FirstName} ${d.User.LastName}`,
              krvnaGrupa: d.BloodType.Type,
              statusZahtjeva: beutifyStatus(statusType),
              brojDoza: d.DoseNumber,
              link: `/kontrolna-ploca/zahtjevi/${status}/${d.Id}`,
            });
          } else {
            return d;
          }
        });

        setTableEntries(transformedArray);
      }
    }, 3000);

    return () => {
      controller.abort();
      clearTimeout(delayedFn);
    };
  }, [searchName, bloodType, status, statusType]);

  return (
    <DashboardLayout>
      <BloodSearch setBlood={setBloodType} setSearchQuery={setSearchName} />
      <Table tableHeaders={tableHeaders} tableEntries={tableEntries} />

      {/* <Paging /> */}
    </DashboardLayout>
  );
};

export default RequestsPage;
