import { useEffect, useState } from "react";
import axios from "../../api/axios";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import Paging from "../../components/Paging/Paging";
import Table from "../../components/Table";
import proniLogo from "./proni.png";
import CKLogo from "./crveni-krst-kriz.jpg";
import OzzLogo from "./odjeljene_zdravstvo.jpg";

export type TableEntriesProps = {
  id: number;
  imePrezime: string;
  krvnaGrupa: string;
  statusZahtjeva: string;
  brojDoza?: number;
  link: string;
};

export type PagingOptionProps = {
  currentPage: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
};

const MainPage = () => {
  const [pagingOption, setPagingOptions] = useState<PagingOptionProps>({
    currentPage: 1,
    totalPages: 1,
    hasNext: true,
    hasPrevious: false,
  });

  const [tableHeaders, setTableHeaders] = useState<string[]>([
    "Ime i prezime",
    "Krvna grupa",
    "Status zahtjeva",
    "",
  ]);

  const [tableEntries, setTableEntries] = useState<TableEntriesProps[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    let loadUserRegReq = async () => {
      try {
        const { data } = await axios.get("/user/paging", {
          signal: controller.signal,
        });

        const transformedList: TableEntriesProps[] = [];

        data.list.forEach((list: any) => {
          transformedList.push({
            id: parseInt(list.Id),
            imePrezime: `${list.FirstName} ${list.LastName}`,
            krvnaGrupa: list.BloodType ? list.BloodType.Type : "N/A",
            statusZahtjeva:
              list.VerifiedByOwner === null
                ? "Na čekanju"
                : list.VerifiedByOwner
                ? "Prihvaćen"
                : "Odbijen",
            link: `/kontrolna-ploca/zahtjev-prijava/${list.Id}`,
          });
        });

        setTableEntries(transformedList);
        setPagingOptions({
          currentPage: data.CurrentPage,
          totalPages: data.TotalPages,
          hasNext: data.HasNext,
          hasPrevious: data.HasPrevious,
        });
      } catch (e) {
        console.error(e);
      }
    };

    loadUserRegReq();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    let loadPage = async () => {
      const { data } = await axios.get(
        `/user/paging?PageNumber=${pagingOption.currentPage}`,
        {
          signal: controller.signal,
        }
      );

      const transformedList: TableEntriesProps[] = [];

      data.list.forEach((list: any) => {
        transformedList.push({
          id: parseInt(list.Id),
          imePrezime: `${list.FirstName} ${list.LastName}`,
          krvnaGrupa: list.BloodType ? list.BloodType.Type : "N/A",
          statusZahtjeva:
            list.VerifiedByOwner === null
              ? "Na čekanju"
              : list.VerifiedByOwner
              ? "Prihvaćen"
              : "Odbijen",
          link: `/kontrolna-ploca/zahtjev-prijava/${list.Id}`,
        });
      });

      setTableEntries(transformedList);
      setPagingOptions({
        currentPage: data.CurrentPage,
        totalPages: data.TotalPages,
        hasNext: data.HasNext,
        hasPrevious: data.HasPrevious,
      });
    };

    loadPage();

    return () => {
      controller.abort();
    };
  }, [pagingOption.currentPage]);

  return (
    <DashboardLayout>
      <h1 className="text-2xl font-bold text-gray-700">
        Pregled zahtjeva za prijavu u aplikaciju
      </h1>

      <Table tableHeaders={tableHeaders} tableEntries={tableEntries} />
      <Paging
        pagingOptions={pagingOption}
        setPagingOptions={setPagingOptions}
      />
      <div
        className="flex pb-20 pr-20 pl-20"
        style={{ justifyContent: "center" }}
      >
        <img
          src={proniLogo}
          alt="Proni logo"
          className="w-1/3"
          style={imageStyle}
        />
        <img
          src={CKLogo}
          alt="Crveni krst logo"
          className="w-1/3"
          style={imageStyle}
        />
        <img
          src={OzzLogo}
          alt="Odjeljenje za zdravstvo logo"
          className="w-1/3 shadow-lg"
          style={imageStyle}
        />
      </div>
    </DashboardLayout>
  );
};

const imageStyle = {
  maxWidth: "7rem",
  maxHeight: "7rem",
  borderRadius: 10,
  margin: "0 1rem",
  boxShadow: "1px 1px 2px 0px #c9c9c9",
};

export default MainPage;
