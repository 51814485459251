import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/Button";
import Logo from "../../components/General/Logo";

export const EnterCode = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-full h-full flex-col md:flex-row">
      <div className="basis-full md:basis-2/3 p-10">
        <div className="w-11/12 md:w-3/5 mx-auto">
          <Link to="/prijava" className="font-semibold flex items-center">
            <ArrowNarrowLeftIcon className="w-7 h-7 inline mr-2" /> Nazad na
            prijavu
          </Link>

          <Logo className="w-40 h-40 mx-auto my-10 md:my-20" />

          <p className="text-md md:text-lg text-center font-bold">
            Unesite kod u polje ispod
          </p>

          <div className="flex justify-center mt-10">
            <input
              type="text"
              name="code0"
              className="rounded-lg bg-slate-200 w-14 h-14 md:w-20 md:h-20 text-3xl text-center focus:outline-none focus:bg-slate-300 mr-5"
            />
            <input
              type="text"
              name="code0"
              className="rounded-lg bg-slate-200 w-14 h-14 md:w-20 md:h-20 text-3xl text-center focus:outline-none focus:bg-slate-300 mr-5"
            />
            <input
              type="text"
              name="code0"
              className="rounded-lg bg-slate-200 w-14 h-14 md:w-20 md:h-20 text-3xl text-center focus:outline-none focus:bg-slate-300 mr-5"
            />
            <input
              type="text"
              name="code0"
              className="rounded-lg bg-slate-200 w-14 h-14 md:w-20 md:h-20 text-3xl text-center focus:outline-none focus:bg-slate-300 mr-5"
            />
          </div>

          <PrimaryButton
            title="Potvrdi kod"
            clickHandler={() => {
              navigate("../reset");
            }}
          />
        </div>
      </div>
      <div className="basis-full md:basis-1/3 p-10 bg-red-600 text-white font-bold flex flex-col justify-center ">
        <h1 className="text-xl md:text-2xl mb-5">Zaboravili ste lozinku</h1>
        <h2 className="text-md md:text-lg">
          Na Vaš mail stići će kod koji ćete morati unijeti kako biste potvrdili
          svoj identitet.
        </h2>
      </div>
    </div>
  );
};
