import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  const { setAuth } = useAuth();

  useEffect(() => {
    localStorage.clear();
    setAuth({ accessToken: "", refreshToken: "", role: "" });
  }, [setAuth]);

  return <Navigate to="/" />;
};

export default Logout;
