import React, { useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import NewsItems from "../../components/NewsItems";
import NewsSearch from "../../components/NewsSearch";
import Paging from "../../components/Paging/Paging";

const News = () => {
  const [searchNews, setSearchNews] = useState<string | null>(null);

  return (
    <DashboardLayout>
      <NewsSearch setSearchQuery={setSearchNews} />

      <NewsItems searchQuery={searchNews} />

      {/* <Paging /> */}
    </DashboardLayout>
  );
};

export default News;
