import { useEffect, useState, FC } from "react";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NewsItem from "./NewsItem";

export type NewsType = {
  id: number;
  title: string;
  excerpt: string;
  date: string;
};

const transformDate = (date: string) => {
  let stringDate = new Date(date);

  return `${stringDate.getDate()}/${
    stringDate.getMonth() + 1
  }/${stringDate.getFullYear()}`;
};

type Props = {
  searchQuery: string | null;
};

const NewsItems: FC<Props> = ({ searchQuery }) => {
  const [newsItems, setNewsItems] = useState<NewsType[] | null>([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let loadNews = async () => {
      const { data } = await axiosPrivate.get("/news");

      let transformedArray: NewsType[] = [];
      data.map((d: any) =>
        transformedArray.push({
          id: d.Id,
          title: d.HeaderText,
          excerpt: d.Text.slice(0, 220) + "...",
          date: transformDate(d.CreatedTime),
        })
      );

      setNewsItems(transformedArray);
    };

    loadNews();
  }, []);

  useEffect(() => {
    const delayedFn = setTimeout(async () => {
      if (searchQuery && searchQuery?.length > 2) {
        const { data } = await axiosPrivate.get("/news", {
          params: { search: searchQuery },
        });

        let transformedArray: NewsType[] = [];
        data.map((d: any) =>
          transformedArray.push({
            id: d.Id,
            title: d.HeaderText,
            excerpt: d.Text.slice(0, 220) + "...",
            date: transformDate(d.CreatedTime),
          })
        );

        setNewsItems(transformedArray);
      }
    }, 3000);

    return () => {
      clearTimeout(delayedFn);
    };
  }, [searchQuery]);

  return (
    <div className="flex flex-wrap justify-between gap-8">
      {newsItems?.map((news, i) => {
        return <NewsItem data={news} key={i} />;
      })}
    </div>
  );
};

export default NewsItems;
