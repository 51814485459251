import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BloodRequestInfoPoints from "../../components/BloodRequestInfoPoints";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import RequestButtons from "../../components/RequestButtons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export type UserInfoType = {
  fullName: string;
  address: string;
  brojDoza: number;
  krvnaGrupa: string;
  svrha: string;
  napomena: string;
  datumZahtjeva: Date;
};

const BloodRequests = () => {
  const [userInfo, setUserInfo] = useState<UserInfoType | null>();
  const [transformedData, setTransformedData] = useState<any>({});

  const { status } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let loadUser = async () => {
      const { data } = await axiosPrivate.get(`/bloodrequest/${id}`);

      setUserInfo({
        fullName: data.User.FirstName+' '+data.User.LastName,
        address: data.Address,
        brojDoza: data.DoseNumber,
        krvnaGrupa: data.BloodType?.Type.toString(),
        svrha: data.PurposeForRequest,
        napomena: data.Note,
        datumZahtjeva: data.RequestDate,
      });

      setTransformedData({
        doseNumber: data.DoseNumber,
        address: data.Address,
        purposeForRequest: data.PurposeForRequest,
        note: data.Note,
        bloodTypeId: data.BloodType?.Id,
      });
    };

    loadUser();
  }, []);

  const renderBottomPart = () => {
    if (status === "odbijeni") {
      return (
        <p className="font-bold text-red-900 w-full md:bg-white text-center mb-10">
          Zahtjev odbijen
        </p>
      );
    }

    if (status === "prihvaceni") {
      return (
        <p className="font-bold w-full md:bg-white text-center mb-10">
          Zahtjev prihvaćen
        </p>
      );
    }

    return <></>;
  };

  const approveRequest = async () => {
    try {
      const data = { ...transformedData, status: true };

      await axiosPrivate.put(`/bloodrequest/${id}`, data);

      navigate(`/kontrolna-ploca/zahtjevi/prihvaceni/${id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const refuseRequest = async () => {
    try {
      const data = { ...transformedData, status: false };

      await axiosPrivate.put(`/bloodrequest/${id}`, data);

      navigate(`/kontrolna-ploca/zahtjevi/odbijeni/${id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const renderButtons = () => {
    if (status === "na-cekanju") {
      return (
        <RequestButtons
          onFailHandler={refuseRequest}
          onSuccessHandler={approveRequest}
        />
      );
    }
    return <></>;
  };

  return (
    <DashboardLayout displayBackBtn={true}>
      <div className="md:bg-white shadow-none md:shadow-md rounded-md p-0 md:p-8">
        <div className="flex justify-between">
          <p className="text-gray-600 text-center md:text-left ">
            Pregled zahtjeva
          </p>
        </div>

        <div className="flex my-10 flex-col md:flex-row mb-5">
          <BloodRequestInfoPoints data={userInfo} />
        </div>
        <div className="mb-32">
          <h2 className="font-bold text-lg text-center md:text-left ">
            Napomena od podnosioca zahtjeva
          </h2>
          <blockquote className="p-4 bg-neutral-100 border-2 border-neutral-200 rounded-lg mt-2">
            {userInfo?.napomena}
          </blockquote>
        </div>
        {renderBottomPart()}
      </div>

      {renderButtons()}
    </DashboardLayout>
  );
};

export default BloodRequests;
