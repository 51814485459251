import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ChangeButton, DangerButton } from "../../components/Button/Button";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { NewsType } from "../../components/NewsItems/NewsItems";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const transformDate = (date: string) => {
  let stringDate = new Date(date);

  return `${stringDate.getDate()}/${
    stringDate.getMonth() + 1
  }/${stringDate.getFullYear()}`;
};

const IndividualNews = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const [news, setNews] = useState<NewsType | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  const closeConfirm = () => {
    setDeleteConfirmation(false);
  };

  const deleteNews = async () => {
    if (id) {
      await axiosPrivate.delete(`/news/${id}`);
      navigate(-1);
    }
  };

  useEffect(() => {
    let loadNews = async () => {
      if (id) {
        const { data } = await axiosPrivate.get(`/news/${id}`);

        setNews({
          id: data.Id,
          title: data.HeaderText,
          excerpt: data.Text,
          date: transformDate(data.CreatedTime),
        });
      }
    };

    loadNews();
  }, []);

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between">
        {deleteConfirmation && (
          <div className="bg-white text-dark border-2 border-gray-200 shadow-custom rounded-lg p-5 absolute">
            <p className="text-lg">
              Da li ste sigurni da želite izbrisati novost?
            </p>
            <p className="text-lg font-bold">{news?.title}</p>
            <div className="flex items-end mt-5 mr-0">
              <DangerButton
                title="Da"
                clickHandler={deleteNews}
                spacingClasses={""}
              />
              <ChangeButton title="Ne" clickHandler={closeConfirm} />
            </div>
          </div>
        )}
        <h2 className="font-bold text-2xl mb-5 duration-150 transition-all ease-in-out">
          {news?.title}
        </h2>
        <div className="flex mb-5">
          <Link
            to={`/kontrolna-ploca/novosti/${news?.id}/uredi`}
            className="mr-3"
          >
            <PencilAltIcon className="w-6 h-6 text-green-600 hover:text-green-800 duration-150 transition-all ease-in-out" />
          </Link>

          <TrashIcon
            className="w-6 h-6 text-red-400 hover:text-red-900 duration-150 transition-all ease-in-out cursor-pointer"
            onClick={(e) => setDeleteConfirmation(true)}
          />
        </div>
      </div>
      <p>{news?.excerpt}</p>
      <p className="mt-5 text-gray-500">{news?.date}</p>
    </DashboardLayout>
  );
};

export default IndividualNews;
