import { FC, Dispatch, SetStateAction } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { AddButton } from "../Button/Button";

type Props = {
  setSearchQuery: Dispatch<SetStateAction<string | null>>;
};

const NewsSearch: FC<Props> = ({ setSearchQuery }) => {
  return (
    <div className="flex flex-wrap justify-between md:flex-nowrap">
      <div className="flex items-center border-2 border-neutral-200 bg-white py-5 px-5 rounded-lg basis-3/5 md:basis-8/12 md:mr-5 mb-5">
        <SearchIcon className="w-5 h-5 mr-2 text-red-500" />
        <input
          type="text"
          name="search"
          placeholder="Naziv ili dio teksta"
          className="w-full outline-none"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="basis-2/6 md:basis-2/12 md:mr-5">
        <select
          name="sort"
          id="sort"
          className="w-full outline-none p-5 border-2 border-neutral-200 rounded-lg bg-white appearance-none"
        >
          <option value="new">Najnovije prvo</option>
          <option value="old">Najstarije prvo</option>
        </select>
      </div>
      <div className="basis-full md:basis-2/12">
        <Link to="/kontrolna-ploca/novosti/dodaj">
          <AddButton clickHandler={console.log} title="Dodaj novost" />
        </Link>
      </div>
    </div>
  );
};

export default NewsSearch;
