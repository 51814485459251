import BloodRequestInfoPoint from "./BloodRequestInfoPoint";
import { FC } from "react";
import {
  HeartIcon,
  LocationMarkerIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { UserInfoType } from "../../pages/BloodRequests/BloodRequests";

type Props = {
  data: UserInfoType | null | undefined;
};

const BloodRequestInfoPoints: FC<Props> = ({ data }) => {
  return (
    <>
      <div className="basis-full md:basis-1/2">
        <BloodRequestInfoPoint
          icon={<UserIcon className="w-10 h-10 stroke-2 stroke-red-500" />}
          points={[
            { title: data?.fullName || "N/A", description: "Ime i prezime" },
          ]}
        />

        <BloodRequestInfoPoint
          icon={
            <LocationMarkerIcon className="w-10 h-10 stroke-2 stroke-red-500" />
          }
          points={[
            {
              title: data?.address || "N/A",
              description: "Adresa za dozu",
            },
          ]}
        />

        <BloodRequestInfoPoint
          icon={<HeartIcon className="w-10 h-10 stroke-2 stroke-red-500" />}
          points={[
            {
              title: data?.brojDoza.toString() || "N/A",
              description: "Broj potrebnih doza",
            },
            {
              title: data?.krvnaGrupa || "N/A",
              description: "Krvna grupa",
            },
          ]}
        />
      </div>
      <div className="basis-full md:basis-1/2">
      <h2 className="font-bold text-lg text-center md:text-left">
      {data?.datumZahtjeva.toString().split('T',1)} {data?.datumZahtjeva.toString().slice(data?.datumZahtjeva.toString().indexOf('T')).slice(1,9)} 
        </h2>
        <blockquote>
        Datum kreiranja
        </blockquote>
      <hr /><br />

      <h2 className="font-bold text-lg text-center md:text-left">
          Svrha za koju je potrebna doza
        </h2>
        <blockquote className="p-4 bg-neutral-100 border-2 border-neutral-200 rounded-lg mt-2">
          {data?.svrha}
        </blockquote>
      </div>
    </>
  );
};

export default BloodRequestInfoPoints;
