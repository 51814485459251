import jwtDecode from 'jwt-decode';
import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    const tokens: any = JSON.parse(
      localStorage.getItem('tokens') || JSON.stringify({ at: '', rt: '' })
    );

    const { data } = await axios.post('/authentication/refresh', {
      accessToken: tokens.at,
      refreshToken: tokens.rt,
    });

    const decodedToken: any = jwtDecode(data.Token);

    const userRole =
      decodedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];

    if (userRole !== 'Admin') {
      throw new Error('Not authorized');
    }

    localStorage.clear();

    localStorage.setItem(
      'tokens',
      JSON.stringify({
        at: data?.Token,
        rt: data?.RefreshToken,
      })
    );

    setAuth((prevState) => {
      return {
        ...prevState,
        accessToken: data?.Token,
        refreshToken: data?.RefreshToken,
        role: userRole,
      };
    });

    return data?.Token;
  };

  return refresh;
};

export default useRefreshToken;
